const selector = '[data-animate], [data-animation]';
const elements = Array.from(document.querySelectorAll(selector));

const io = new IntersectionObserver(onElementIntersection, {
	root: null,
	rootMargin: '-25%',
});

export function init() {
	elements.forEach(element => {
		if (element.dataset.animation) {
			element.classList.add(element.dataset.animation);
		}

		io.observe(element);
	});
}

function onElementIntersection(entries) {
	entries.filter(e => e.isIntersecting).forEach(e => {
		animate(e.target);
		io.unobserve(e.target);
	});
}

function animate(element) {
	setTimeout(function() {
		element.classList.add('play');
	}, element.dataset.delay);
}
