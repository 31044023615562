import { clamp } from './utils/clamp';

const elements = Array.from(document.querySelectorAll('.js-spinbutton'));

export function init() {
	elements.forEach(element => create(element));
}

function create(element) {
	let timer;
	let currentValue;
	let input = element.querySelector('input');

	let incrementButton = document.createElement('button');
	let decrementButton = document.createElement('button');

	change(input.value);

	incrementButton.setAttribute('type', 'button');
	decrementButton.setAttribute('type', 'button');
	incrementButton.setAttribute('tabindex', '-1');
	decrementButton.setAttribute('tabindex', '-1');

	incrementButton.classList.add('c-spinbutton__increment');
	decrementButton.classList.add('c-spinbutton__decrement');

	incrementButton.addEventListener('mousedown', () => autoChange(1));
	decrementButton.addEventListener('mousedown', () => autoChange(-1));

	incrementButton.addEventListener('mouseup', clearTimer);
	decrementButton.addEventListener('mouseup', clearTimer);

	incrementButton.addEventListener('mouseout', clearTimer);
	decrementButton.addEventListener('mouseout', clearTimer);

	function autoChange(amount) {
		change(Number(input.value) + amount);

		timer = setTimeout(() => {
			clearTimeout(timer);
			timer = setInterval(() => change(Number(input.value) + amount), 100);
		}, 300);
	}

	function clearTimer() {
		clearTimeout(timer);
		clearInterval(timer);
	}

	element.append(incrementButton);
	element.prepend(decrementButton);

	input.setAttribute('pattern', '[0-9]*');
	input.setAttribute('inputmode', 'numeric');
	input.setAttribute('autocomplete', 'off');
	input.setAttribute('aria-role', 'spinbutton');
	input.setAttribute('aria-valuemin', element.dataset.min);
	input.setAttribute('aria-valuemax', element.dataset.max);

	input.addEventListener('keydown', handleKeydown);
	input.addEventListener('blur', () => change(input.value));

	// input.addEventListener('input', () => {
	// 	// User might be trying to clear input so lets stop interfering
	// 	if (input.value === '') {
	// 		return;
	// 	}

	// 	change(input.value);
	// });

	function increment(step = 1) {
		change(currentValue + step);
	}

	function decrement(step = 1) {
		change(currentValue - step);
	}

	function change(value) {
		if (value === '') {
			value = element.dataset.default;
		}

		value = clamp(
			Number(value),
			Number(element.dataset.min),
			Number(element.dataset.max),
		);

		currentValue = value;
		input.value = currentValue;
		input.setAttribute('aria-valuenow', currentValue);
	}

	function handleKeydown(event) {
		if (event.key === 'ArrowUp') {
			increment();
			event.preventDefault();
		}

		if (event.key === 'ArrowDown') {
			decrement();
			event.preventDefault();
		}

		if (event.key === 'End') {
			event.preventDefault();
			change(Number(element.dataset.max));
		}

		if (event.key === 'Home') {
			event.preventDefault();
			change(Number(element.dataset.min));
		}
	}
}
