export function init() {
	const elements = Array.from(document.querySelectorAll('.js-toggle'));

	elements.forEach(element => create(element));
}

function create(element) {
	const target = document.getElementById(element.dataset.target);

	if (!target) {
		return;
	}

	let expanded = element.dataset.expanded == 'true';

	target.setAttribute('aria-hidden', !expanded);

	element.setAttribute('tabindex', 0);
	element.setAttribute('role', 'button');
	element.setAttribute('aria-expanded', 'false');
	element.setAttribute('aria-controls', target.id);

	element.addEventListener('keydown', onKeydown);
	element.addEventListener('click', () => toggle());

	function onKeydown(event) {
		if (!(event.key == 'Enter' || event.key == ' ')) {
			return;
		}

		toggle();
		event.preventDefault();
	}

	function toggle() {
		expanded = !expanded;

		target.setAttribute('aria-hidden', !expanded);
		element.setAttribute('aria-expanded', expanded);
	}
}
