import Flickity from 'flickity';

export function init() {
	const elements = Array.from(document.querySelectorAll('.js-inspire'));

	elements.forEach(element => create(element));
}

function create(element) {
	return new Flickity(element, {
		cellAlign: 'left',
		contain: true,
		prevNextButtons: false,
		watchCSS: true,
	});
}
