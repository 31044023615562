export function init() {
	let elements = Array.from(document.querySelectorAll('[data-embed]'));

	const io = new IntersectionObserver(onIntersection, {
		threshold: 0,
		rootMargin: '15%',
	});

	elements.forEach(elem => io.observe(elem));

	function onIntersection(entries) {
		const inViewEmbeds = entries.filter(entry => entry.isIntersecting);

		inViewEmbeds.forEach(entry => {
			entry.target.innerHTML = entry.target.dataset.embed;
		});
	}
}
