import { dialog } from '@rynpsc/dialog';
import { getScrollbarWidth } from '../modules/utils/utils';

let menu;
let activePane;

const elements = {
	menu: document.querySelector('.js-menu'),
	body: document.querySelector('.js-menu-body'),
	back: document.querySelector('.js-menu-back'),
	toggles: Array.from(document.querySelectorAll('.js-menu-toggle')),
	paneToggles: Array.from(document.querySelectorAll('.js-menu-pane-toggle')),
};

export function init() {
	if (!elements.menu || !elements.back) {
		return;
	}

	menu = dialog('menu');

	if (menu) {
		menu.create();
	}

	menu.on('dialog:open', onOpen);
	menu.on('dialog:close', onClose);

	elements.toggles.forEach(element => element.addEventListener('click', function() {
		menu.toggle();
		event.preventDefault();
	}));

	elements.back.addEventListener('click', closeActivePane);
	elements.paneToggles.forEach(element => element.addEventListener('click', openPane));
}

function openPane(event) {
	const target = document.getElementById(event.currentTarget.dataset.target);

	event.preventDefault();

	activePane = target;
	target.classList.add('is-open');
	elements.menu.scrollTo(0, 0);
	elements.back.classList.add('is-visible');
	elements.menu.classList.add('has-open-pane');
	let height = activePane.scrollHeight;
	elements.body.style.minHeight = `${height}px`;
	activePane.querySelector('.js-menu-pane-content').style.height = `${height}px`;
}

function closeActivePane() {
	if (!activePane) {
		return;
	}

	elements.body.style.minHeight = '';
	elements.back.classList.remove('is-visible');
	elements.menu.classList.remove('has-open-pane');
	activePane.classList.remove('is-open');
}

function onOpen() {
	document.body.style.overflow = 'hidden';
	document.body.style.paddingRight = `${getScrollbarWidth()}px`;
}

function onClose() {
	closeActivePane();
	document.body.style = '';
}
