const feed = document.querySelector('.js-image-feed');

let count = 0;
let feedData = [];

const images = [];
const MIN_IMAGE_WIDTH = 100;
const MAX_IMAGE_WIDTH = 240;

const io = new IntersectionObserver(onIntersection, {
	rootMargin: '20%',
});

export function init() {
	if (!feed) {
		return;
	}

	io.observe(feed);
}

function fetchFeed() {
	return fetch(feed.dataset.url)
		.then(response => response.json())
		.then(data => feedData = data);
}

function onIntersection(entries) {
	entries.forEach(entry => {
		if (entry.isIntersecting) {
			fetchFeed().then(() => update());
			window.addEventListener('resize', update);
		} else {
			window.removeEventListener('resize', update);
		}
	});
}

function update() {
	if (!feedData || !Object.prototype.hasOwnProperty.call(feedData, 'media')) {
		return;
	}

	const feedWidth = feed.offsetWidth;
	const numberRequired = Math.round((feedWidth / clamp((100 + (feedWidth * 0.1)), MIN_IMAGE_WIDTH, MAX_IMAGE_WIDTH)));

	if (numberRequired === count) {
		return;
	}

	feed.innerHTML = '';

	for (let i = 0; i < numberRequired; i++) {
		feed.appendChild(createImage(feedData['media'][i]));
	}

	count = numberRequired;
}

function clamp(num, min, max) {
	return num <= min ? min : num >= max ? max : num;
}

function createImage(media) {
	const element = document.createElement('a');
	element.classList.add('c-image-feed__image');
	element.href = media['permalink'];
	element.target = '_blank';

	element.innerHTML = `<img src="${media['thumbnailUrl'] || media['mediaUrl']}" alt="">`;

	images.push(element);

	return element;
}
