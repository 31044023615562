const elements = Array.from(document.querySelectorAll('.js-fbl'));

export function init() {
	elements.forEach(el => {
		el.addEventListener('mouseenter', () => toggleHoverClass(el, true));
		el.addEventListener('mouseout', () => toggleHoverClass(el, false));
	});

	function toggleHoverClass(element, force) {
		const target = element.querySelector('.js-fblt');

		if (target) {
			target.classList.toggle('hover', force);
		}
	}
}
