import Flickity from 'flickity';

export function init() {
	const toggle = document.querySelector('.js-ch-toggle');
	const element = document.querySelector('.js-ch-categories');

	if (!element || !toggle) {
		return;
	}

	const carousel = new Flickity(element, {
		cellAlign: 'left',
		contain: true,
		pageDots: false,
		prevNextButtons: false,
		freeScroll: true,
	});

	let expanded = toggle.dataset.expanded == 'true';

	toggle.setAttribute('aria-controls', element.id);
	toggle.style.visibility = 'visible';

	toggleCategories(expanded);

	toggle.addEventListener('click', function() {
		expanded = !expanded;

		toggleCategories(expanded);
	});

	function toggleCategories(expand) {
		toggle.setAttribute('aria-expanded', expand);
		toggle.textContent = expand ? 'Hide categories' : 'Show categories';
		element.setAttribute('aria-hidden', !expand);
		element.classList.toggle('is-expanded', expand);
		carousel.resize();
	}
}
