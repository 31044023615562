import defaults from './defaults';
import { modulo } from '../utils/utils';

export const instances = {};

/**
 * Get the accordion instance with the corresponding ID.
 *
 * @param {string} id ID of accordion element.
 * @returns {Object|null} Returns the instance of one is found matching the ID, null otherwise.
 */
export function getInstanceById(id) {
	if (id === undefined) {
		return null;
	}

	if (!Object.prototype.hasOwnProperty.call(instances, id)) {
		return null;
	}

	return instances[id];
}

/**
 * Accordion.
 *
 * @param {string} id The ID of the containing HTMLElement.
 * @param {object} options
 */
export function accordion(id, options) {
	const element = document.getElementById(id);
	const config = { ...defaults, ...options };

	if (!element) {
		return;
	}

	if (Object.prototype.hasOwnProperty.call(instances, id)) {
		return instances[id];
	}

	const items = [];
	const activeIDs = [];
	const events = {};
	let initialised = false;

	const headers = Array.from(element.querySelectorAll('[data-for]'));
	const descriptions = Array.from(element.querySelectorAll('[data-description-for]'));

	/**
	 * Gets an accordion item by either it's numerical index or id.
	 *
	 * @param {number|string}
	 * @returns {object|undefined}
	 */
	function getItem(value) {
		let index = getItemIndex(value);

		return items[index];
	}

	/**
	 * Gets an accordion items index by either it's numerical index or id.
	 *
	 * @param {number|string} value
	 * @returns {number} Returns the value passed in if it's a number or the
	 * index of the first item with the matching ID if passed a string,
	 * -1 otherwise.
	 */
	function getItemIndex(value) {
		let index;

		if (typeof value == 'number') {
			index = value;
		} else if (typeof value == 'string') {
			index = items.findIndex(item => item.id == value);
		} else {
			new TypeError('ID must be typeof number or string.');
		}

		return index;
	}

	/**
	 * Init
	 */
	function init() {
		if (initialised) {
			return;
		}

		headers.forEach((header) => {
			const id = header.dataset.for;
			const panel = document.getElementById(id);
			const control = document.createElement('button');

			control.type = 'button';
			control.id = `${id}-label`;
			control.innerHTML = header.innerHTML;

			header.innerHTML = '';
			header.appendChild(control);

			control.setAttribute('aria-controls', id);
			control.setAttribute('aria-expanded', false);

			control.addEventListener('click', onHeaderClick);
			control.addEventListener('keydown', onHeaderKeydown);

			panel.setAttribute('role', 'region');
			panel.setAttribute('aria-labelledby', `${id}-label`);

			let item = { id, active: false, control, panel, parent: undefined, disabled: false };

			for (let i = items.length - 1; i >= 0; i--) {
				const element = items[i];
				const contains = element.panel.contains(panel);

				if (contains) {
					item.parent = element.id;
					break;
				}
			}

			items.push(item);

			if (header.dataset.expanded !== undefined) {
				open(id, false);
			}

			if (header.dataset.disabled !== undefined) {
				disable(id);
			}

			element.classList.add(config.initialisedClass);

			initialised = true;

			emit('initialised');
		});

		descriptions.forEach(function setUpDescription(description) {
			const target = description.dataset.descriptionFor;
			const descriptionId = description.id || `${target}-description`;
			const item = getItem(target);

			if (item) {
				description.id = descriptionId;
				item.control.setAttribute('aria-describedby', descriptionId);
			} else {
				console.error(`Accordion: Invalid description - No panel with the ID "${target}".`);
			}
		});
	}

	/**
	 * Destroy accordion - removes any classes or attributes added in `init`,
	 * removes any registered event listensers, and removes the accordion instance.
	 */
	function destroy() {
		if (!initialised) {
			return;
		}

		openAll();

		emit('destroy');

		element.classList.remove(config.initialisedClass);

		items.forEach(item => {
			const { control, panel } = item;

			control.removeAttribute('aria-controls');
			control.removeAttribute('aria-expanded');
			control.parentNode.innerHTML = control.innerHTML;
			control.classList.remove(config.activeHeaderClass);

			control.removeEventListener('click', onHeaderClick);
			control.removeEventListener('keydown', onHeaderKeydown);

			panel.style = '';
			panel.classList.remove(config.activePanelClass);
		});

		items.length = 0;
		activeIDs.length = 0;
		delete instances[id];

		for (const [name, handlers] of Object.entries(events)) {
			handlers.forEach(handler => off(name, handler));

			delete events[name];
		}

		initialised = false;
	}

	/**
	 * Focus the next accordion control.
	 */
	function focusNext() {
		focusPreviousOrNext(false);
	}

	/**
	 * Focus the previous accordion control.
	 */
	function focusPrevious() {
		focusPreviousOrNext(true);
	}

	/**
	 * Focus the first accordion control.
	 */
	function focusFirst() {
		items[0].control.focus();
	}

	/**
	 * Focus the last accordion control.
	 */
	function focusLast() {
		items[items.length - 1].control.focus();
	}

	/**
	 * Focuses the previous or next control, wraps around to first or last control when required.
	 *
	 * @param {boolean} previous If true focus the previous control, otherwise focus the next.
	 */
	function focusPreviousOrNext(previous) {
		let active = document.activeElement;
		let activeIndex = items.findIndex(item => item.control == active);

		if (activeIndex == -1) {
			return false;
		}

		const candidates = items.filter(item => {
			return !item.parent || activeIDs.includes(item.parent);
		});

		activeIndex = candidates.findIndex(item => item.control == active);

		let newIndex = previous ? activeIndex - 1 : activeIndex + 1;

		candidates[modulo(newIndex, candidates)].control.focus();
	}

	/**
	 * Toggle the panel with the provided ID.
	 *
	 * @param {string} id
	 */
	function toggle(id) {
		let { active } = getItem(id);

		active ? close(id) : open(id);
	}

	/**
	 * Expand an accordion item(s), by either it's index or id.
	 *
	 * @param {array|number|string} id
	 * @param {object} options
	 */
	function open(id, options) {
		if (Array.isArray(id)) {
			id.forEach(id => _expand(id, options));
		} else {
			_expand(id, options);
		}
	}

	/**
	 * Collapse an accordion item(s), by either it's index or id.
	 *
	 * @param {array|number|string} id
	 * @param {object} options
	 */
	function close(ids, options) {
		if (Array.isArray(ids)) {
			ids.forEach(id => _collapse(id, options));
		} else {
			_collapse(ids, options);
		}
	}

	/**
	 * Expand all panels.
	 */
	function openAll() {
		items.forEach(item => open(item.id, { animate: false, multiselect: true }));
	}

	/**
	 * Collapse all panels.
	 */
	function closeAll() {
		items.reverse().forEach(item => close(item.id, { animate: false }));
	}

	/**
	 * Enable a panel, allowing to be collapsed or expanded.
	 *
	 * @param {number|string} id
	 */
	function enable(id) {
		let index = getItemIndex(id);
		let { control } = items[index];

		items[index].disabled = false;
		control.setAttribute('aria-disabled', false);
	}

	/**
	 * Disable a panel, preventing it from being collapsed or expanded.
	 *
	 * @param {number|string} id
	 */
	function disable(id) {
		let index = getItemIndex(id);
		let { control } = items[index];

		items[index].disabled = true;
		control.setAttribute('aria-disabled', true);
	}

	/**
	 * Expand the panel with the provided ID.
	 *
	 * @param {string} id
	 */
	function _expand(id, options = {
		animate: config.animate,
		multiselect: config.multiselect,
	}) {
		let animate = options.animate;
		let index = getItemIndex(id);
		let { active, control, panel, disabled, parent } = items[index];

		if (active || disabled) {
			return;
		}

		if (!emit('close', { cancelable: true })) {
			return;
		}

		if (parent && getItem(parent).active == false) {
			animate = false;
		}

		items[index].active = true;
		control.setAttribute('aria-expanded', true);

		panel.classList.add(config.activePanelClass);
		control.parentNode.classList.add(config.activeHeaderClass);

		if (!animate) {
			panel.style.height = '';
			panel.style.display = 'block';
		} else {
			let startHeight = panel.clientHeight;

			panel.style.height = 'auto';
			panel.style.display = 'block';

			let endHeight = panel.clientHeight;

			animateHeight(panel, startHeight, endHeight);
		}

		if (parent && getItem(parent).active == false) {
			_expand(parent, options);
		}

		if (!options.multiselect) {
			close(activeIDs.filter(isNotParent, options));
		}

		function isNotParent(i) {
			const item = getItem(i);
			return !item.panel.contains(panel) && item.parent == parent;
		}

		activeIDs.push(id);
	}

	/**
	 * Collapse the panel with the provided ID.
	 *
	 * @param {string} id
	 */
	function _collapse(id, options = { animate: config.animate, multiselect: config.multiselect }) {
		let index = getItemIndex(id);
		let { active, panel, control, disabled } = items[index];

		if (!active || disabled) {
			return;
		}

		if (!emit('close', { cancelable: true })) {
			return;
		}

		items[index].active = false;

		control.setAttribute('aria-expanded', false);

		panel.classList.remove(config.activePanelClass);
		control.parentNode.classList.remove(config.activeHeaderClass);

		if (!options.animate) {
			panel.style.display = 'none';
		} else {
			animateHeight(panel, panel.clientHeight, 0);
		}

		{
			let index = activeIDs.indexOf(id);

			if (index > -1) {
				activeIDs.splice(index, 1);
			}
		}
	}

	/**
	 * Handles the header click event.
	 *
	 * @param {Event} event
	 */
	function onHeaderClick(event) {
		event.preventDefault();
		toggle(event.currentTarget.getAttribute('aria-controls'));
	}

	/**
	 * Handles the header keydown event.
	 *
	 * @param {Event} event
	 */
	function onHeaderKeydown(event) {
		switch (event.key) {
			case 'ArrowUp':
				focusPrevious();
				event.preventDefault();
				break;
			case 'ArrowDown':
				focusNext();
				event.preventDefault();
				break;
			case 'End':
				focusLast();
				break;
			case 'Home':
				focusFirst();
				break;
		}
	}

	/**
	 * Animates the height of an element between two values.
	 *
	 * @param {HTMLElement} element The element to animate.
	 * @param {number} start The start height of the element.
	 * @param {number} end The end height of the element.
	 */
	function animateHeight(element, start, end) {
		window.requestAnimationFrame(() => {
			element.style.display = 'block';
			element.style.height = `${ start }px`;

			window.requestAnimationFrame(() => {
				element.style.height = `${ end }px`;
				element.addEventListener('transitionend', onTransitionend);
			});
		});
	}

	/**
	 *
	 * @param {Event} event
	 */
	function onTransitionend(event) {
		if (event.propertyName !== 'height') {
			return;
		}

		const element = event.currentTarget;

		if (getInnerDemensions(element).height == 0) {
			element.style.display = 'none';
		}

		element.style.height = '';

		element.removeEventListener('transitionend', onTransitionend);
	}

	function getInnerDemensions(node) {
		let computedStyle = getComputedStyle(node);

		let height = node.clientHeight;
		let width = node.clientWidth;

		height -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
		width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
		return { height, width };
	}

	/**
	 * Wrapper method to add an event listener.
	 *
	 * @param {string} type The event name.
	 * @param {Function} handler Callback function to handle the event.
	 */
	function on(type, handler) {
		element.addEventListener(type, handler);

		// Track event listeners to remove when calling destroy.
		(events[type] || (events[type] = [])).push(handler);
	}

	/**
	 * Wrapper method to remove an event listener.
	 *
	 * @param {string} type The event name.
	 * @param {Function} handler Callback function to handle the event.
	 */
	function off(type, handler) {
		element.removeEventListener(type, handler);
	}

	/**
	 * Dispatches a custom event.
	 *
	 * @param {string} name - The event name.
	 * @returns {boolean} False if preventDefault() was called, true otherwise.
	 */
	function emit(name, options) {
		const defaultOptions = {
			bubbles: true,
			cancelable: false,
		};

		const prefixedName = `accordion:${name}`;

		let event = new CustomEvent(
			prefixedName,
			{ ...defaultOptions, ...options },
		);

		return element.dispatchEvent(event);
	}

	const instance = {
		on,
		off,
		init,
		destroy,
		open,
		close,
		toggle,
		enable,
		disable,
		openAll,
		closeAll,
	};

	return instances[id] = instance;
}
