import Pikaday from 'pikaday';

// UTC seconds todays date and add 2 days of seconds

// get the current date & time
let startDate = Date.now();
startDate += 1000 * 60 * 60 * 24 * 2;
startDate = new Date(startDate);

export function init() {
	const inputs = Array.from(document.querySelectorAll('.js-datepicker'));

	inputs.forEach(function createDatePicker(input) {
		new Pikaday({
			field: input,
			format: 'DD/MM/YYYY',
			minDate: startDate,
		});

		// Prevent onscreen keyboard on iOS, Android, etc.
		input.setAttribute('inputmode', 'none');
	});
}
