/* global google */

import { styles } from './style';
import { loadScript } from '../utils/utils';

const apiKey = 'AIzaSyBEAjrX7JD2Kim9yEz5_zmSRC0orOtTDj8';
const scriptURL = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;

export function init() {
	const elements = Array.from(document.querySelectorAll('.js-google-map'));

	if (!elements.length) {
		return;
	}

	loadGoogleMaps(() => {
		elements.forEach(element => {
			const lat = parseFloat(element.dataset.lat);
			const lng = parseFloat(element.dataset.lng);

			const map = createMap(element, {
				center: { lat, lng },
			});

			createMarker(map, lat, lng);
		});
	});
}

function loadGoogleMaps(callback) {
	const loaded = typeof google === 'object' && typeof google.maps === 'object';

	loaded ? callback() : loadScript(scriptURL, callback);
}

function createMap(canvas, options) {
	const defaults = {
		disableDefaultUI: true,
		mapTypeControl: false,
		styles: styles,
		zoom: 11,
		zoomControl: true,
	};

	return new google.maps.Map(canvas, { ...defaults, ...options });
}

function createMarker(map, lat, lng) {
	const icon = {
		url: '/assets/icons/map-marker.svg',
		size: new google.maps.Size(54, 54),
		origin: new google.maps.Point(0, 0),
		anchor: new google.maps.Point(27, 27),
	};

	return new google.maps.Marker({
		map: map,
		icon: icon,
		position: { lat, lng },
	});
}
