import { focusTrap } from '@rynpsc/focus-trap';
import { getScrollbarWidth } from '../modules/utils/utils';

export default function drawer(id) {
	const element = document.getElementById(id);

	if (!element) {
		return;
	}

	let trap;
	let initialFocus;
	let isOpen = false;
	const openClass = 'is-open';
	const contents = element.querySelector('.js-drawer-contents');

	if (!contents) {
		return;
	}

	init();

	function init() {
		trap = focusTrap(element);

		const openers = Array.from(document.querySelectorAll(`[data-open-drawer="${id}"]`));
		const closers = Array.from(document.querySelectorAll(`[data-close-drawer="${id}"]`));

		openers.forEach(element => element.addEventListener('click', function(event) {
			open();
			event.preventDefault();
		}));

		closers.forEach(element => element.addEventListener('click', function(event) {
			close();
			event.preventDefault();
		}));
	}

	function onKeydown(event) {
		if (event.key == 'Escape') {
			close();
		}
	}

	function onDocumentClick(event) {
		if (!contents.contains(event.target) && isOpen) {
			close();
		}
	}

	function open() {
		if (isOpen) {
			return;
		}

		element.classList.add(openClass);

		document.body.style.width = '100%';
		document.body.style.overflow = 'hidden';
		document.body.style.paddingRight = `${getScrollbarWidth()}px`;

		initialFocus = document.activeElement;

		element.addEventListener('click', onDocumentClick);
		element.addEventListener('touchstart', onDocumentClick);
		document.addEventListener('keydown', onKeydown);
		contents.addEventListener('transitionend', onTransitionEnd);

		isOpen = true;
	}

	function close() {
		if (!isOpen) {
			return;
		}

		element.classList.remove(openClass);

		document.body.style = '';

		document.removeEventListener('keydown', onKeydown);
		element.removeEventListener('click', onDocumentClick);
		element.removeEventListener('touchstart', onDocumentClick);

		isOpen = false;
		trap.deactivate(initialFocus);
	}

	function onTransitionEnd() {
		trap.activate();
		contents.removeEventListener('transitionend', onTransitionEnd);
	}

	return { open, close };
}
