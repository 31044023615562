import Flickity from 'flickity';

const selectedClass = 'is-selected';

export function init() {
	const elements = Array.from(document.querySelectorAll('.js-pg'));

	elements.forEach(element => create(element));
}

function create(element) {
	let currentIndex = 0;

	const primaryElement = element.querySelector('.js-pg-primary');
	const thumbnails = Array.from(element.querySelectorAll('.js-pg-thumbnail'));

	if (!primaryElement) {
		return;
	}

	const carousel = new Flickity(primaryElement, {
		cellAlign: 'left',
		contain: true,
		initialIndex: currentIndex,
		lazyLoad: true,
		prevNextButtons: false,
	});

	thumbnails.forEach(element => element.addEventListener('click', navigate));

	element.classList.add('is-initialised');
	thumbnails[currentIndex].classList.add(selectedClass);
	carousel.reposition();
	carousel.on('change', onSelect);

	function navigate(event) {
		const target = event.currentTarget;
		const index = thumbnails.indexOf(target);

		carousel.select(index, false, true);
	}

	function onSelect(index) {
		const previousIndex = currentIndex;

		currentIndex = index;
		thumbnails[currentIndex].classList.add(selectedClass);
		thumbnails[previousIndex].classList.remove(selectedClass);
	}
}
