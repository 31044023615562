import throttle from 'lodash.throttle';
import classifyPoint from 'robust-point-in-polygon';

let activeNav;
let closeTimer;
let isTouch = false;
let mouseData = { x: 0, y: 0 };
let movingHorizontally = false;
let timer;

const SAMPLE_RATE = 80;
const activeClass = 'is-open';
const triggers = Array.from(document.querySelectorAll('.js-mega-nav-trigger'));

export function init() {
	document.addEventListener('mousemove', throttle(trackMouse, SAMPLE_RATE));

	triggers.forEach(element => element.addEventListener('mouseleave', onMouseLeave));
	triggers.forEach(element => element.addEventListener('mouseenter', onMouseEnter, false));
	triggers.forEach(element => element.addEventListener('touchstart', onTouchstart, { passive: false }));
}

function onTouchstart(event) {
	isTouch = true;

	let trigger = event.currentTarget;
	let dropdown = document.getElementById(trigger.dataset.target);

	if (activeNav && event.currentTarget === activeNav.trigger) {
		closeActiveNav();
		event.preventDefault();

		return;
	}

	if (activeNav && outsideNav(event.clientX, event.clientY)) {
		closeActiveNav();
	}

	if (!dropdown) {
		return;
	}

	event.preventDefault();
	openNav(trigger, dropdown);
}

function onMouseEnter(event) {
	let x = event.clientX;
	let y = event.clientY;

	let dropdown;
	let trigger = event.currentTarget;

	if (trigger.dataset.target) {
		dropdown = document.getElementById(trigger.dataset.target);
	}

	timer = setInterval(shouldActivate, SAMPLE_RATE);

	function shouldActivate() {
		const open = Math.hypot(
			Math.abs(mouseData.x - x),
			Math.abs(mouseData.y - y),
		) < 5 || movingHorizontally;

		x = mouseData.x;
		y = mouseData.y;

		if (open) {
			closeActiveNav();
			clearInterval(timer);

			if (!dropdown) {
				return;
			}

			openNav(trigger, dropdown);
		}
	}
}

function onMouseLeave() {
	clearInterval(timer);
	timer = undefined;
}

function openNav(trigger, dropdown) {
	activeNav = { trigger, dropdown };

	activeNav.dropdown.classList.add(activeClass);

	closeTimer = setInterval(closeTimerFn, SAMPLE_RATE * 2);
}

function closeTimerFn() {
	if (!isTouch && outsideNav(mouseData.x, mouseData.y)) {
		closeActiveNav();
	}
}

function closeActiveNav() {
	if (!activeNav) {
		return;
	}

	clearInterval(closeTimer);
	activeNav.trigger.classList.remove(activeClass);
	activeNav.dropdown.classList.remove(activeClass);

	activeNav = undefined;
}

function trackMouse(event) {
	isTouch = false;

	const x = event.clientX;
	const y = event.clientY;

	clearInterval(closeTimer);

	if (activeNav) {
		const rect = activeNav.dropdown.getBoundingClientRect();

		let slopeA = slope({ x, y }, { x: rect.left, y: rect.top });
		let slopeB = slope({ x, y }, { x: rect.right, y: rect.top });

		let prevSlopeA = slope(mouseData, { x: rect.left, y: rect.top });
		let prevSlopeB = slope(mouseData, { x: rect.right, y: rect.top });

		const movingTowardMenu = (slopeA < prevSlopeA + 5 || slopeB < prevSlopeB + 5);

		if ((outsideNav(x, y) && !movingTowardMenu)) {
			closeActiveNav();
		} else {
			clearInterval(closeTimer);
			closeTimer = setInterval(closeTimerFn, SAMPLE_RATE * 2);
		}
	}

	movingHorizontally = Math.abs(mouseData.x - x) > Math.abs(mouseData.y - y);

	mouseData.x = x;
	mouseData.y = y;
}

function slope(a, b) {
	return Number((b.y - a.y) / (b.x - a.x)).toFixed(3);
}

function outsideNav(x, y) {
	const point = classifyPoint(getPolygon2(activeNav), [x + 1, y + 1]);

	return point == 1;
}

function getPolygon2(item) {
	const padding = 15;

	let t = item.trigger.getBoundingClientRect();
	let d = item.dropdown.getBoundingClientRect();

	/**
	 *         C +---------+ D
	 *           | Trigger |
	 *	A +---- B +---------+ E -------------+ F
	 *	  |                                  |
	 *	  |             Dropdown             |
	 *	  |                                  |
	 *	F +----------------------------------+ G
	 */

	return [
		/* A */ [d.left, d.top],
		/* B */ [t.left, t.bottom],
		/* C */ [t.left, t.top],
		/* D */ [t.left + t.width, t.top],
		/* E */ [t.right, t.bottom],
		/* F */ [d.left + d.width, d.top],
		/* G */ [d.left + d.width, d.top + d.height + padding],
		/* H */ [d.left, d.top + d.height + padding],
	];
}
