/**
 * Async font loading via FontFaceObserver
 */

import Cookies from 'js-cookie';
import FontFaceObserver from 'fontfaceobserver';

const typefaces = {
	Asap: [
		{ weight: 400, style: 'normal' },
		{ weight: 400, style: 'italic' },
		{ weight: 600, style: 'normal' },
	],
	Lyon: [
		{ weight: 400, style: 'normal' },
		{ weight: 400, style: 'italic' },
	],
};

export function load() {
	if (Cookies.get('fonts-loaded')) {
		return false;
	}

	loadFonts().then(function handleFontsLoaded() {
		document.documentElement.classList.add('fonts-loaded');
		Cookies.set('fonts-loaded', '1', { expires: 7 });
	});
}

function loadFonts() {
	const fonts = [];

	Object.keys(typefaces).forEach(family => {
		typefaces[family].map(variant => {
			const loader = new FontFaceObserver(family, variant);
			fonts.push(loader.load());
		});
	});

	return Promise.all(fonts);
}
