const element = document.querySelector('.js-method');
const rulesField = document.querySelector('.js-rules');
const shippingFields = document.querySelector('.js-address-container');

const delivery = document.getElementById('shippingMethodHandle-2');

export function init() {
	if (element) {
		element.addEventListener('change', event => {
			if (event.target.name !== 'shippingMethodHandle') {
				return;
			}

      if (delivery.checked) {
			  shippingFields.classList.add('is-active');
      } else {
        shippingFields.classList.remove('is-active');
      }
		});

    if (delivery.checked) {
      shippingFields.classList.add('is-active');
    } else {
      shippingFields.classList.remove('is-active');
    }
	}

	if (rulesField) {
		rulesField.addEventListener('change', event => {
			if (event.target.name !== 'shippingRule') {
				return;
			}

			const shippingMethodHandleInputs = Array.from(element.querySelectorAll('[name="shippingMethodHandle"]'));

			shippingMethodHandleInputs.forEach(input => {
				if (input.checked == false) {
					input.checked = true;
				} else {
					input.checked = false;
				}
			});

			rulesField.classList.toggle('inactive');

			if (delivery.checked) {
        shippingFields.classList.add('is-active');
      } else {
        shippingFields.classList.remove('is-active');
      }
		});
	}
}
