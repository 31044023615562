import { accordion } from './accordion/accordion';

export function init() {
	const elements = Array.from(document.querySelectorAll('.js-accordion'));

	elements.forEach(element => {
		const instance = accordion(element.id);

		if (!instance) {
			return;
		}

		instance.init();
	});
}
