export default {
	/**
	 * Class to add to root element on initialisation.
	 */
	initialisedClass: 'is-initialised',

	/**
	 * Class to add active panel.
	 */
	activePanelClass: 'is-active',

	/**
	 * Class to add to active header.
	 */
	activeHeaderClass: 'is-active',

	/**
	 * Animate the opening and closing of panels.
	 */
	animate: true,

	/**
	 * Allow multiple panels to expanded at once.
	 */
	multiselect: true,
};
