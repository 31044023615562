import Flickity from 'flickity';
import throttle from 'lodash.throttle';

const MILLISECONDS_IN_SECOND = 1000;
const DURATION = 15 * MILLISECONDS_IN_SECOND;
const REFRESH_RATE = 10;

export function init() {
	Array.from(document.querySelectorAll('.js-hero')).forEach(element => create(element));
}

function create(element) {
	let progress = 0;
	let activeIndex = 0;
	let player;

	const imageElement = element.querySelector('.js-hero-images');
	const heroContent = Array.from(document.querySelectorAll('.js-hero-c'));

	const cellProgressBar = document.querySelector('.js-hero-pb-cell');
	const overallProgressBar = document.querySelector('.js-hero-pb-overall');

	const navElements = Array.from(element.querySelectorAll('.js-hero-nav-item'));

	const images = new Flickity(imageElement, {
		contain: true,
		on: { ready: ready() },
		pageDots: false,
		prevNextButtons: false,
	});

	const io = new IntersectionObserver(entries => {
		entries.forEach(entry => entry.isIntersecting ? play() : pause());
	}, { threshold: 0.75 });

	io.observe(element);

	play();
	resize();
	activateNavItem(activeIndex);

	images.on('pointerUp', play);
	images.on('pointerDown', pause);
	images.on('change', onSlideChange);

	navElements.forEach(element => element.addEventListener('click', onNavClick));
	window.addEventListener('resize', throttle(resize, 100));

	function resize() {
		const bp = window.getComputedStyle(element, '::after').getPropertyValue('content');

		const navContainer = document.querySelector('.js-hero-nav');
		const contentContainer = document.querySelector('.js-hero-cc');

		const maxNavHeight = Math.max(...navElements.map(element => element.clientHeight));
		const maxContentHeight = Math.max(...heroContent.map(element => element.clientHeight));

		contentContainer.style.height = `${maxContentHeight}px`;
		navContainer.style.height = (bp == '"l"' ? '' : `${maxNavHeight}px`);
	}

	function play() {
		player = setInterval(function() {
			progress += REFRESH_RATE;
			updateProgressBar();
		}, REFRESH_RATE);
	}

	function pause() {
		clearInterval(player);
	}

	function updateProgressBar() {
		let cellProgress = progress / DURATION * 100;
		let stepProgress = (images.selectedIndex / images.cells.length) * 100;
		let cellProgressAsPercentOfTotal = cellProgress / images.cells.length;
		let totalProgress = stepProgress + cellProgressAsPercentOfTotal;

		cellProgressBar.style.width = `${cellProgress}%`;
		overallProgressBar.style.width = `${totalProgress}%`;

		if (progress >= DURATION) {
			progress = 0;
			images.next(true, true);
		}
	}

	function onSlideChange(i) {
		progress = 0;
		activateNavItem(i);
	}

	function ready() {
		element.classList.add('is-initialised');
	}

	function onNavClick(event) {
		let i = navElements.indexOf(event.currentTarget);
		images.select(i, true, true);
	}

	function activateNavItem(index) {
		const selectedClass = 'is-selected';

		navElements[activeIndex].classList.remove(selectedClass);
		navElements[index].classList.add(selectedClass);

		heroContent[activeIndex].classList.remove(selectedClass);
		heroContent[index].classList.add(selectedClass);

		activeIndex = index;
	}
}
