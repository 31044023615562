import Cookies from 'js-cookie';

const className = 'is-visible';
const cookieName = 'cookie-consent';
const notice = document.getElementById('cookie-notice');
const controls = Array.from(document.querySelectorAll('.js-cookie-consent'));

export function init() {
	if (notice && !Cookies.get(cookieName)) {
		controls.forEach(control => control.addEventListener('click', e => onClick(e)));
	}
}

function onClick() {
	notice.classList.remove(className);
	Cookies.set(cookieName, 'true', { expires: 365 });
}
