import Flickity from 'flickity';

export function init() {
	const elements = Array.from(document.querySelectorAll('.js-content-gallery'));

	elements.forEach(element => create(element));
}

function create(element) {
	const carousel = new Flickity(element, {
		cellAlign: 'left',
		contain: true,
		prevNextButtons: false,
		groupCells: true,
		dragThreshold: 10,
	});

	update();
	carousel.on('select', update);

	function update() {
		// If the number of visible cells = total cells, disable interactivity.
		const disable = carousel.selectedElements.length !== carousel.cells.length;

		carousel.options.draggable = disable;
		carousel.updateDraggable();
		element.querySelector('.flickity-page-dots').hidden = !disable;
	}
}
