import * as fontLoader from './modules/font-loader';
import { init as accordions } from './modules/accordions';
import { init as categoryHeader } from './modules/category-header';
import { init as contentGallery } from './modules/content-gallery';
import { init as filterDrawer } from './modules/search/filter-drawer';
import { init as hero } from './modules/hero';
import { init as imageFeed } from './modules/image-feed';
import { init as inspireCarousel } from './modules/inspire-carousel';
import { init as maps } from './modules/google-maps';
import { init as megaNav } from './modules/header/mega-nav';
import { init as menu } from './modules/menu';
import { init as productGallery } from './modules/product-gallery';
import { init as searchFilters } from './modules/search/filters';
import { init as stickyNavigation } from './modules/header/sticky';
import { init as toggles } from './modules/toggle';
import LazyLoader from './modules/lazy-loader';
import { init as cookieNotice } from './modules/cookie-notice';
import { init as embedLoader } from './modules/lazy-embed';
import { init as animation } from './modules/animation';
import { init as fauxBlockLink } from './modules/faux-block-link';
import { init as pikadayDatepicker } from './modules/pikaday-datepicker';
import { init as spinbutton } from './modules/spinbutton';
import { init as billingAddress } from './modules/billing-address';
import { init as inputToggle } from './modules/input-toggle';

init();

function init() {
	fontLoader.load();
	stickyNavigation();
	megaNav();
	accordions();
	categoryHeader();
	contentGallery();
	filterDrawer();
	inspireCarousel();
	productGallery();
	searchFilters();
	toggles();
	menu();
	hero();
	cookieNotice();
	embedLoader();
	LazyLoader().update();
	animation();
	fauxBlockLink();
	pikadayDatepicker();
	spinbutton();
	billingAddress();
	inputToggle();

	maps();
	imageFeed();
}
