import throttle from 'lodash.throttle';

const navigation = document.querySelector('.js-sticky-navigation');

let height;
let scrollOffset;
let isFixed = false;
let animationFrameRequested = false;

const fixedClass = 'is-fixed';

export function init() {
	if (!navigation) {
		return;
	}

	update();

	document.addEventListener('scroll', updatePosition);
	window.addEventListener('resize', throttle(update, 100));
}

function update() {
	if (animationFrameRequested) {
		return;
	}

	window.requestAnimationFrame(() => {
		updatePosition();
		calculateOffset();

		animationFrameRequested = true;
	});

	animationFrameRequested = false;
}

function updatePosition() {
	window.scrollY >= scrollOffset ? fix() : unFix();
}

function calculateOffset() {
	const rect = navigation.getBoundingClientRect();
	scrollOffset = rect.top + window.scrollY;
	height = rect.height;
}

function fix() {
	if (isFixed) {
		return;
	}

	isFixed = true;
	navigation.classList.add(fixedClass);
	document.body.style.paddingTop = `${height}px`;
}

function unFix() {
	if (!isFixed) {
		return;
	}

	isFixed = false;
	navigation.classList.remove(fixedClass);
	document.body.style.paddingTop = '';
}
